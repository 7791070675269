body,
html {
  margin: 0;
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

.loader:after {
  content: ' ';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 2s linear infinite;
}

.error-page {
  background-image: url('./assets/404.gif');
  background-repeat: no-repeat;
  background-position: 50% 20%;
  background-size: 60% 60%;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: block;
  animation: 200ms linear 50ms btnAnimation;
}

@keyframes btnAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  appearance: textfield;
}

.content {
  top: 64px !important;
  width: calc(100vw - 240px);
  height: calc(100vh - 64px);
  position: relative;
  left: 240px;
}
.dashContent {
  top: 64px !important;
  width: calc(100vw - 240px);
  height: calc(100vh - 64px);
  position: relative;
  left: 240px;
}

.Adscontent {
  top: 64px;
  width: calc(100vw - 240px) !important;

  /* height: max-content; */
  position: relative;
  left: 240px;
  padding: 2rem;
}

.topbar {
  width: calc(100vw - 240px);
  position: fixed;
  left: 240px;
}
.activeLink {
  background: black;
  width: 100% !important;
  height: fit-content !important;
}
/* .activeLink:first-child {
 
} */

.activeLink > div {
  background-color: #fff;
}
.activeLink > div > h1 {
  color: black !important;
  width: fit-content !important;
}
.activeLink > div > .icon {
  color: black !important;
  width: fit-content !important;
}

.hoverLink {
  background: black;
  width: 100% !important;
  height: fit-content !important;
}
.hoverLink:hover {
  height: fit-content !important;
}
.hoverLink:hover > div {
  background-color: #fff;
  transition: all 0.5s ease-in;
}
.hoverLink:hover > div > h1 {
  color: black !important;
  width: fit-content !important;
}
.hoverLink:hover > div > .icon {
  color: black !important;
  width: fit-content !important;
}

@layer components {
  .my-react-select-container {
    width: 30%;
  }
  .my-react-select-container .my-react-select__control {
    @apply border border-primary pl-2 bg-white rounded outline-none font-sans text-xs w-full;
  }
  .my-react-select-container .my-react-select__control--is-focused {
    @apply shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-neutral-100  border-neutral-300;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 bg-neutral-100 hover:bg-neutral-200;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600;
  }
  .my-react-select-container .my-react-select__placeholder {
    @apply text-[#9ca3af];
    @apply bg-white;
  }
}

.css-1q89gsl-control {
  background-color: #fff !important;
}
.css-bl2mj7-control {
  background-color: #fff !important;
}

.my-react-select__menu {
  /* background-color: blue !important; */
  color: black !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.css-hlj6pa-MuiDialogActions-root,
.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton,
.css-1hbyad5-MuiTypography-root {
  display: none !important;
}
.css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
  background-color: black !important;
  color: white !important;
}
.Mui-selected {
  background-color: #bdbdbd !important;
}
.css-1vcokmn-MuiCalendarOrClockPicker-root {
  border: 1px solid black !important;
  border-radius: 4px;
}
.MuiPickersDay-hiddenDaySpacingFiller + span {
  display: none !important;
}

/* the slides */
.slick-slide > div {
  margin: 0 0px;
  padding: 0 10px;
}
the parent .slick-list {
  margin: 0 -7px;
}

.slick-prev:before,
.slick-next:before {
  color: #000000 !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 38px !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.selected-flag {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
